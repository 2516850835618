import { atom } from "jotai";

export interface Tracking {
  trackPageView: OrUndefined<TrackPageViewFunction>;
}
export type TrackPageViewFunction = (data?: Record<string, unknown>) => void;

export const trackingAtom = atom<Tracking>({
  trackPageView: undefined,
});
