/**
 * @generated SignedSource<<ac656f3211ed841d6767a5c873cb0fb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentAgendaContentTopic$data = {
  readonly description: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly newsAgenda: {
    readonly secondaryDescription: string | null | undefined;
  } | null | undefined;
  readonly updatedDate: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"hooksNewsAgendaTopic">;
  readonly " $fragmentType": "contentAgendaContentTopic";
};
export type contentAgendaContentTopic$key = {
  readonly " $data"?: contentAgendaContentTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentAgendaContentTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentAgendaContentTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NewsAgenda",
      "kind": "LinkedField",
      "name": "newsAgenda",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryDescription",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksNewsAgendaTopic"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "f512bb2e9ed61e96bcafeeb18473af9a";

export default node;
