/**
 * @generated SignedSource<<723e71338799702ef3a2a3026609f736>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

const node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseDynamicMetaTags",
  "selections": [{
    "alias": null,
    "args": null,
    "concreteType": "KeyValuePair",
    "kind": "LinkedField",
    "name": "metatags",
    "plural": true,
    "selections": [{
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }],
    "storageKey": null
  }],
  "type": "DynamicMetaTags",
  "abstractKey": "__isDynamicMetaTags"
};
node.hash = "bac39c7e3441f446b6cda383defd2361";
export default node;