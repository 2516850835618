/**
 * @generated SignedSource<<aff2f651b2f6f35a8c46be053b99bda0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

const node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseDeepLinkTagsBase",
  "selections": [{
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }, {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "urlAlias",
    "storageKey": null
  }, {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  }],
  "type": "BaseWithApplicationAndUrlAlias",
  "abstractKey": "__isBaseWithApplicationAndUrlAlias"
};
node.hash = "1c98e995a9d961a24c1a01f8a4f5e250";
export default node;