import _extends from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["appIconLinkTags", "appLinksMetaTags", "appManifestLinkTag", "isAppLinksDisabled", "options"];
var __jsx = React.createElement;
import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import merge from "deepmerge";
import { NextSeo } from "next-seo";
import React from "react";
import { config } from "shared/data";
import { useCanonicalUrl } from "shared/hooks";
import { DefaultTitleTemplate, Title } from "./consts";
import { useKeywordMetaTags } from "./hooks";
export const SharedPageSeo = _ref => {
  let {
      appIconLinkTags,
      appLinksMetaTags,
      appManifestLinkTag,
      isAppLinksDisabled,
      options = {}
    } = _ref,
    extraSeoProps = _objectWithoutProperties(_ref, _excluded);
  // default description
  const {
    canonicalUrl
  } = useCanonicalUrl();
  const keywordMetaTags = useKeywordMetaTags();

  /**
   * a bug reported by QA: https://scmp-product-tech.atlassian.net/browse/SCMPWEB-3496
   * said that the page title will change to the default title we pass to <DefaultSeo>
   * and back to desired title during the hydration process.
   *
   * the reason is that the title we passed to <NextSeo> will be unmounted by the 'WithRelay' suspense.
   * The <DefaultSeo> use the default title. Then the suspense result, the page mount again,
   * the desired title appear again.
   *
   * as the conclusion, we don't use <DefaultSeo> but using this wrapper component of <NextSeo> to provide default values.
   * during suspense, no <DefaultSeo> is executed, the page seo meta tags & title will remain as in the SSR source code.
   *
   * P.S. the '{...extraSeoProps}' should be at the end so that the page using this component can override the props.
   */

  const additionalMetaTags = isAppLinksDisabled ? [...keywordMetaTags] : [...appLinksMetaTags, ...keywordMetaTags];
  const defaultProps = {
    additionalLinkTags: [appManifestLinkTag, ...appIconLinkTags],
    additionalMetaTags,
    canonical: canonicalUrl,
    defaultTitle: Title,
    facebook: {
      appId: config.social.facebook.appId
    },
    openGraph: {
      images: [],
      siteName: Title,
      type: "website"
    },
    robotsProps: {
      maxImagePreview: "large"
    },
    titleTemplate: DefaultTitleTemplate,
    twitter: {
      cardType: "summary_large_image",
      site: `@${config.social.twitter.scmp.accountName}`
    }
  };
  const filteredProps = Object.fromEntries(Object.entries(merge(defaultProps, extraSeoProps, options)).filter(_ref2 => {
    let [_, value] = _ref2;
    return value !== undefined;
  }));
  return __jsx(NextSeo, _extends({}, filteredProps, {
    "data-qa": "SharedPageSeo-NextSeo"
  }));
};
SharedPageSeo.displayName = "SharedPageSeo";
export const ViewportTag = {
  content: "width=device-width, maximum-scale=1, user-scalable=no, initial-scale=1",
  name: "viewport"
};
export const defaultAdditionalMetaTags = [{
  content: "max-image-preview:large",
  keyOverride: "robot-1",
  name: "robots"
}, {
  content: "25fd909c3fd10ded451415fc076ba8",
  name: "pocket-site-verification"
}, {
  content: "#ffffff",
  name: "theme-color"
}, {
  content: "yes",
  name: "mobile-web-app-capable"
}, ViewportTag];