/**
 * @generated SignedSource<<570c72443c197a420bc2f3871163953f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsNewsAgendaTopicTopic$data = {
  readonly topicTypes: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsNewsAgendaTopicTopic";
};
export type helpersCheckIsNewsAgendaTopicTopic$key = {
  readonly " $data"?: helpersCheckIsNewsAgendaTopicTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsNewsAgendaTopicTopic">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsNewsAgendaTopicTopic"
};

(node as any).hash = "1700b404e4716a12e489536213773020";

export default node;
