import merge from "deepmerge";
import type { LinkTag, MetaTag, NextSeoProps } from "next-seo";
import { type FunctionComponent } from "react";
import React from "react";

import type { Props as SharedPageSeoProps } from "shared/components/meta";
import { SharedPageSeo } from "shared/components/meta";
import { config } from "shared/data";
import { Edition, editionMetaDescription } from "shared/lib/edition";
import { getAssetPrefix } from "shared/lib/utils";

import DefaultImage from "./default-image.png";

export type Props = Omit<
  SharedPageSeoProps,
  "appIconLinkTags" | "appLinksMetaTags" | "appManifestLinkTag"
>;

export const PageSeo: FunctionComponent<Props> = ({ options = {}, ...extraSeoProps }) => {
  const description = editionMetaDescription[Edition.International];

  const defaultProps: NextSeoProps = {
    description,
    openGraph: {
      images: extraSeoProps.openGraph?.images?.length ? [] : [{ url: DefaultImage.src }],
    },
  };

  const filteredProps = Object.fromEntries(
    Object.entries(merge(defaultProps, extraSeoProps, options)).filter(
      ([_, value]) => value !== undefined,
    ),
  );

  return (
    <SharedPageSeo
      appIconLinkTags={appIconLinkTags}
      appLinksMetaTags={appLinksMetaTags}
      appManifestLinkTag={appManifestLinkTag}
      {...filteredProps}
    />
  );
};
PageSeo.displayName = "PageSeo";

const appLinksMetaTags: MetaTag[] = [
  {
    content: config.app.ios.storeId,
    property: "al:ios:app_store_id",
  },
  {
    content: config.app.ios.name,
    property: "al:ios:app_name",
  },
  {
    content: config.app.android.package,
    property: "al:android:package",
  },
  {
    content: config.app.android.name,
    property: "al:android:app_name",
  },
  {
    content: `app-id=${config.app.ios.storeId}`,
    name: "apple-itunes-app",
  },
];

const appManifestLinkTag: LinkTag = {
  href: "/static/manifest.json",
  rel: "manifest",
};

const appIconLinkTags: LinkTag[] = [
  {
    href: `${getAssetPrefix()}/favicon.ico`,
    rel: "shortcut icon",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-192x192.png`,
    rel: "icon",
    sizes: "192x192",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-256x256.png`,
    rel: "icon",
    sizes: "256x256",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-192x192.png`,
    rel: "apple-touch-icon",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-192x192.png`,
    rel: "apple-touch-icon-precomposed",
  },
];

export const ViewportTag = {
  content: "width=device-width, maximum-scale=1, user-scalable=no, initial-scale=1",
  name: "viewport",
};

export const defaultAdditionalMetaTags = [
  {
    content: "max-image-preview:large",
    keyOverride: "robot-1",
    name: "robots",
  },
  {
    content: "25fd909c3fd10ded451415fc076ba8",
    name: "pocket-site-verification",
  },
  {
    content: "#ffffff",
    name: "theme-color",
  },
  {
    content: "yes",
    name: "mobile-web-app-capable",
  },
  ViewportTag,
];
