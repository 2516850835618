var _hooksUseDeepLinkTagsBase, _hooksUseDynamicMetaTags;
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.v2.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/esnext.async-iterator.reduce.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { isControlledTagKey } from "./enums";
const defaultKeyword = "South China Morning Post, News, Opinion, China, Hong Kong, World, US, Asia, Business, Economy, Technology, Lifestyle, Sport";
export const useKeywordMetaTags = function () {
  let keyword = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultKeyword;
  let shouldKeepDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const keywordSeparator = ", ";
  const getUniqArray = array => [...new Set(array)];
  const content = shouldKeepDefault ? getUniqArray([...defaultKeyword.split(keywordSeparator), ...keyword.split(keywordSeparator)]).join(keywordSeparator) : keyword;
  return [{
    content,
    name: "keywords"
  }, {
    content,
    name: "news_keywords"
  }];
};
export const useDeepLinkTags = entity_ => {
  const entity = useFragment(_hooksUseDeepLinkTagsBase !== void 0 ? _hooksUseDeepLinkTagsBase : _hooksUseDeepLinkTagsBase = require("../../queries/__generated__/hooksUseDeepLinkTagsBase.graphql"), entity_);
  const deepLinkPath = useMemo(() => {
    switch (entity.__typename) {
      case "%other":
        return `scmp:/${entity.urlAlias}`;
      case "Article":
        return `scmp:/${entity.urlAlias}?entity=node&type=article&id=${entity.entityId}`;
      case "Section":
        return `scmp:/${entity.urlAlias}?entity=term&type=section&id=${entity.entityId}`;
      case "Topic":
        return `scmp:/${entity.urlAlias}?entity=term&type=topic&id=${entity.entityId}`;
    }
  }, [entity]);
  return [{
    content: deepLinkPath,
    property: "al:ios:url"
  }, {
    content: deepLinkPath,
    property: "al:android:url"
  }];
};
export const useDynamicMetaTags = entity_ => {
  const entity = useFragment(_hooksUseDynamicMetaTags !== void 0 ? _hooksUseDynamicMetaTags : _hooksUseDynamicMetaTags = require("../../queries/__generated__/hooksUseDynamicMetaTags.graphql"), entity_);
  return (entity.metatags ?? []).reduce((metaTags, metaTag) => {
    if (metaTag && metaTag.key && metaTag.value) {
      if (isControlledTagKey(metaTag.key)) {
        metaTags.controlled[metaTag.key] = metaTag.value;
      } else {
        metaTags.uncontrolled[metaTag.key] = metaTag.value;
      }
    }
    return metaTags;
  }, {
    controlled: {},
    uncontrolled: {}
  });
};